import { LegalDocumentWL, UploadedClaimDocument } from "@api/graphql/types";
import { Loctool } from "@monster/loctool";

export enum ClaimFrontendStatus {
    filed = "filed",
    in_progress = "in_progress",
    waiting_client_task = "waiting_client_task",
    closed = "closed",
}

export enum ClaimStatus {
    accepted_finished = "accepted_finished",
    accepted_payment_pending = "accepted_payment_pending",
    assistance_opened_by_partner = "assistance_opened_by_partner",
    automatic_accepted_finished = "automatic_accepted_finished",
    call_arranged = "call_arranged",
    canceled_by_user = "canceled_by_user",
    checking_local_expert = "checking_local_expert",
    closed_by_partner = "closed_by_partner",
    closed_draft_timeout = "closed_draft_timeout",
    closed_totaled = "closed_totaled",
    closed_with_regress = "closed_with_regress",
    closed_without_payment = "closed_without_payment",
    closed_without_payment_invalid_data = "closed_without_payment_invalid_data",
    draft_canceled = "draft_canceled",
    open_draft = "open_draft",
    open_reopened = "open_reopened",
    open_submitted = "open_submitted",
    reimbursement_closed_with_payment = "reimbursement_closed_with_payment",
    reimbursement_closed_without_payment = "reimbursement_closed_without_payment",
    reimbursement_opened_by_partner = "reimbursement_opened_by_partner",
    rejected = "rejected",
    rejected_timeout = "rejected_timeout",
    repair_done = "repair_done",
    repair_in_progress = "repair_in_progress",
    sending_to_partner = "sending_to_partner",
    waiting_for_client = "waiting_for_client",
    waiting_for_client_payment = "waiting_for_client_payment",
    waiting_for_expert = "waiting_for_expert",
    waiting_for_operator = "waiting_for_operator",
    ordered_from_partner = "ordered_from_partner",
}

export class Helpers {
    public static getLegalDocumentPdfLink(legalDocument: LegalDocumentWL | null): string {
        if (!legalDocument?.url) {
            console.warn(`File url not available for ${legalDocument?.category} legal document`);
            return window.location.href;
        }
        const fileUrl = encodeURIComponent(`${process.env.REACT_APP_DOCUMENT_URL_BASE}${legalDocument.url}`);
        return `${process.env.REACT_APP_PDF_VIEWER_URL ?? ""}/?file=${fileUrl}`;
    }

    public static getLocationParameterByName(name: string, url: string): string | null {
        name = name.replace(/[[]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    public static getFileExtension(fileName: string | null): string {
        return (/(?:\.([^.]+))?$/.exec(fileName ?? "") ?? ["", "N/A"])[1];
    }

    public static nullFilter<T>(item: T | null): item is T {
        return item !== null;
    }

    public static readonly humanFileSize = (bytes: number, si = true, dp = 1) => {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + " B";
        }

        const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + " " + units[u];
    };

    public static openLivePersonChat() {
        const engagementId = process.env[`REACT_APP_LIVEPERSON_ENGAGEMENT_ID_${Loctool.instance.getConfig().locale.toUpperCase()}`];
        if (engagementId && typeof lpTag !== "undefined") {
            try {
                lpTag.taglets.rendererStub.click(engagementId);
            } catch (error) {
                console.dir(error);
            }
        }
    }

    public static getFrontendClaimStatus(status: ClaimStatus): ClaimFrontendStatus {
        switch (status) {
            case ClaimStatus.open_submitted:
            case ClaimStatus.open_reopened:
                return ClaimFrontendStatus.filed;
            case ClaimStatus.waiting_for_operator:
            case ClaimStatus.waiting_for_expert:
            case ClaimStatus.sending_to_partner:
            case ClaimStatus.checking_local_expert:
            case ClaimStatus.call_arranged:
            case ClaimStatus.repair_in_progress:
            case ClaimStatus.repair_done:
            case ClaimStatus.waiting_for_client_payment:
            case ClaimStatus.reimbursement_opened_by_partner:
                return ClaimFrontendStatus.in_progress;
            case ClaimStatus.waiting_for_client:
                return ClaimFrontendStatus.waiting_client_task;
            case ClaimStatus.closed_with_regress:
            case ClaimStatus.rejected:
            case ClaimStatus.rejected_timeout:
            case ClaimStatus.closed_totaled:
            case ClaimStatus.closed_without_payment:
            case ClaimStatus.closed_without_payment_invalid_data:
            case ClaimStatus.closed_by_partner:
            case ClaimStatus.closed_draft_timeout:
            case ClaimStatus.canceled_by_user:
            case ClaimStatus.reimbursement_closed_without_payment:
            case ClaimStatus.reimbursement_closed_with_payment:
                return ClaimFrontendStatus.closed;
            default:
                console.warn("Unhandled claimStatus", status);
                return ClaimFrontendStatus.closed;
        }
    }

    public static getClaimStatuses(status: ClaimFrontendStatus): ClaimStatus[] {
        switch (status) {
            case ClaimFrontendStatus.filed:
                return [ClaimStatus.open_submitted, ClaimStatus.open_reopened];
            case ClaimFrontendStatus.in_progress:
                return [
                    ClaimStatus.waiting_for_operator,
                    ClaimStatus.waiting_for_expert,
                    ClaimStatus.sending_to_partner,
                    ClaimStatus.checking_local_expert,
                    ClaimStatus.call_arranged,
                    ClaimStatus.repair_in_progress,
                    ClaimStatus.repair_done,
                    ClaimStatus.waiting_for_client_payment,
                    ClaimStatus.reimbursement_opened_by_partner,
                ];
            case ClaimFrontendStatus.waiting_client_task:
                return [ClaimStatus.waiting_for_client];
            case ClaimFrontendStatus.closed:
                return [
                    ClaimStatus.closed_with_regress,
                    ClaimStatus.rejected,
                    ClaimStatus.rejected_timeout,
                    ClaimStatus.closed_totaled,
                    ClaimStatus.closed_without_payment,
                    ClaimStatus.closed_without_payment_invalid_data,
                    ClaimStatus.closed_by_partner,
                    ClaimStatus.closed_draft_timeout,
                    ClaimStatus.canceled_by_user,
                    ClaimStatus.reimbursement_closed_without_payment,
                    ClaimStatus.reimbursement_closed_with_payment,
                ];
            default:
                return [];
        }
    }

    public static getUploadedClaimDocumentFileName(file: UploadedClaimDocument) {
        return file.file.name?.replace(`_${file.id}`, "");
    }
}
