import { Api, Gql } from "@api/Api";
import { CountryCode } from "@api/graphql/types";
import { Loctool } from "@monster/loctool";
import { ApplicationState } from "@redux/Reducers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const defaultResult: Gql.getAvailableCountriesForClaimCause_getAvailableCountriesForClaimCause = {
    countryList: [],
    defaultValue: null,
    __typename: "AvailableClaimCountryList",
};

export const useClaimCountryCodes = () => {
    const claimCauseId = useSelector((state: ApplicationState) => state.session.claimInput?.claimCauseId);
    const [result, setResult] = useState(defaultResult);

    useEffect(() => {
        if (!claimCauseId) {
            setResult(defaultResult);
            return;
        }

        const languageCode = Loctool.instance.getConfig().locale.toUpperCase() as Gql.LanguageCode;
        const updateCountryCodes = async () => {
            try {
                const newResult = await Api.getAvailableCountriesForClaimCause(CountryCode.DE, languageCode, claimCauseId);
                setResult(newResult);
            } catch (error) {
                console.error(error);
                setResult(defaultResult);
            }
        };

        updateCountryCodes();
    }, [claimCauseId]);

    return result;
};
