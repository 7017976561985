import React, { useEffect, useMemo } from "react";
import { useTheme } from "styled-components";
import { useForm } from "react-hook-form";
import { Box, Flex } from "@monster/shared";
import { Button, DateInput, Select, Surface, TextField, Tooltip, TooltipContent, TooltipTrigger, useNotification } from "@monster/chr-ui";
import { SVGChrIcon32BasicsQuestionMark } from "@monster/chr-ui/dist/svg_assets";
import { DateInputParser, DateUtils } from "@utils/DateHelpers";
import { FormHelpers } from "@utils/FormHelpers";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@redux/Reducers";
import { Validator } from "@utils/Validator";
import { batchActions } from "redux-batched-actions";
import { SessionActions } from "@redux/actions/sessionActions";
import { ClaimInputType } from "@redux/reducers/sessionReducer";
import { Api } from "@api/Api";
import { AppStateActions, PageSteps } from "@redux/actions/appStateActions";
import { Loctool, LoctoolHTMLMessage, LoctoolMessage, LoctoolNumber } from "@monster/loctool";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { GraphqlErrorMessage } from "@api/CustomTypes";
import { BankAccountNumberInput } from "@components/Input/BankAccountNumberInput";
import { CurrencyInput } from "@components/Input/CurrencyInput";
import { ClaimCauseHelpers, ClaimCauseType, TravelClaimCauseType } from "@utils/ClaimCauseHelpers";
import { Constants } from "@utils/Constants";
import { ClaimCountryCode } from "@api/graphql/types";
import { useClaimCountryCodes } from "@utils/useClaimCountryCodes";

type IForm = {
    dateOfIncident: string;
    description: string;
    claimAmount: string;
    iban: string;
    claimCountryCode?: ClaimCountryCode | null;
};

type Props = {
    disabled: boolean;
};

export const ClaimDescriptionForm = ({ disabled }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const insuranceData = useSelector((state: ApplicationState) => state.session.relatedData?.insuranceData);
    const existingClaimData = useSelector((state: ApplicationState) => state.session.relatedData?.claimData);
    const claimInputData = useSelector((state: ApplicationState) => state.session.claimInput);
    const claimCountryData = useClaimCountryCodes();
    const showNotification = useNotification();

    const { dateOfIncidentMin, dateOfIncidentMax } = useMemo(() => {
        const now = DateUtils.now();
        const firstDay = DateInputParser.parse(insuranceData?.firstDay);
        const lastDay = DateInputParser.parse(insuranceData?.lastDay);
        const fallbackMinMax = {
            dateOfIncidentMin: firstDay,
            dateOfIncidentMax: DateUtils.isAfter(now, lastDay) ? lastDay : now,
        };

        const claimCauseType = ClaimCauseHelpers.getClaimCauseTypeById(claimInputData?.claimCauseId);
        if (!claimCauseType) {
            return fallbackMinMax;
        }
        if (([TravelClaimCauseType.cancellation, TravelClaimCauseType.flight_delay, TravelClaimCauseType.other] as ClaimCauseType[]).includes(claimCauseType)) {
            return {
                dateOfIncidentMin: insuranceData?.created ? DateUtils.parseISO(insuranceData?.created) : firstDay,
                dateOfIncidentMax: DateUtils.isAfter(now, lastDay) ? lastDay : now,
            };
        }
        return fallbackMinMax;
    }, [claimInputData?.claimCauseId, insuranceData?.created, insuranceData?.firstDay, insuranceData?.lastDay]);

    const {
        handleSubmit,
        register,
        setValue,
        setError,
        watch,
        formState: { errors },
    } = useForm<IForm>({
        defaultValues: {
            claimAmount: claimInputData?.claimAmount?.toString() ?? "",
            dateOfIncident: claimInputData?.dateOfIncident ?? DateUtils.format(dateOfIncidentMin) ?? "",
            description: claimInputData?.description ?? "",
            claimCountryCode: claimInputData?.claimCountryCode,
            iban: claimInputData?.iban ?? "",
        },
        mode: "onTouched",
    });

    useEffect(() => {
        setValue("claimCountryCode", undefined);
    }, [claimInputData?.claimCauseId, setValue]);

    useEffect(() => {
        if (claimCountryData.defaultValue?.countryCode) {
            setValue("claimCountryCode", claimCountryData.defaultValue?.countryCode);
        } else if (claimCountryData.countryList.length === 1) {
            setValue("claimCountryCode", claimCountryData.countryList[0].countryCode);
        }
    }, [setValue, claimCountryData]);

    const dateOfIncident = watch("dateOfIncident");
    const description = watch("description");

    const onSubmit = async (values: IForm) => {
        const inputData: Partial<ClaimInputType> = {
            claimAmount: parseFloat(values.claimAmount),
            dateOfIncident: values.dateOfIncident,
            description: values.description,
            iban: values.iban,
            claimCountryCode: values.claimCountryCode,
        };

        try {
            if (existingClaimData?.id && claimInputData?.dateOfIncident) {
                await Api.updateClaim({
                    claimId: existingClaimData.id,
                    claimAmount: inputData.claimAmount,
                    dateOfIncident: inputData.dateOfIncident,
                    description: inputData.description!,
                    claimCountryCode: inputData.claimCountryCode,
                    iban: inputData.iban!,
                });
            } else {
                await Api.createClaim({
                    claimAmount: inputData.claimAmount,
                    claimCauseId: claimInputData?.claimCauseId,
                    dateOfIncident: inputData.dateOfIncident,
                    description: inputData.description!,
                    iban: inputData.iban!,
                    claimCountryCode: inputData.claimCountryCode,
                    insuranceId: insuranceData!.id,
                });
            }
            const relatedData = await Api.getRelatedData();
            dispatch(
                batchActions([SessionActions.updateRelatedData(relatedData), SessionActions.updateClaimInputData(inputData), AppStateActions.setCurrentStep(PageSteps.ClaimMandatoryAttachments)])
            );
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                if (error.message === GraphqlErrorMessage.iban_checksum_fail) {
                    setError("iban", { message: error.intlMessage, type: "api" });
                } else {
                    showNotification(error.intlMessage);
                }
            }
        }
    };

    if (disabled) {
        return (
            <Surface $variant="greyBorder">
                <Box as="dl" $style={{ margin: 0, padding: 16, "dd+dt": { marginTop: theme.text.bodyDesktop.lineHeight } }}>
                    <Box as="dt" $style={{ fontWeight: 700 }}>
                        <LoctoolMessage id="components.claimdescriptionform.when.label" />
                    </Box>

                    <Box as="dd" $style={{ margin: 0 }}>
                        {DateUtils.formatForLocale(DateInputParser.parse(claimInputData!.dateOfIncident!))}
                    </Box>

                    <Box as="dt" $style={{ fontWeight: 700 }}>
                        <LoctoolMessage id="components.claimdescriptionform.eventdescription" />
                    </Box>

                    <Box as="dd" $style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: claimInputData?.description ?? "".replace(/\n/g, "<br/>") }} />

                    {claimCountryData.countryList.length > 1 && (
                        <>
                            <Box as="dt" $style={{ fontWeight: 700 }}>
                                <LoctoolMessage id="components.claimdescriptionform.eventLocation" />
                            </Box>

                            <Box as="dd" $style={{ margin: 0 }}>
                                {claimInputData?.claimCountryCode}
                            </Box>
                        </>
                    )}

                    <Box as="dt" $style={{ fontWeight: 700 }}>
                        <LoctoolMessage id="components.claimdescriptionform.amount.label" />
                    </Box>

                    <Box as="dd" $style={{ margin: 0 }}>
                        <LoctoolNumber value={claimInputData?.claimAmount ?? 0} format="currency" currency={"EUR"} />
                    </Box>

                    <Box as="dt" $style={{ fontWeight: 700 }}>
                        <LoctoolMessage id="components.claimdescriptionform.iban.label" />
                    </Box>

                    <Box as="dd" $style={{ margin: 0 }}>
                        {claimInputData?.iban}
                    </Box>
                </Box>
            </Surface>
        );
    }

    return (
        <>
            <Flex.Container
                $style={{
                    columnGap: 16,
                    backgroundColor: theme.color.greyN,
                    margin: "-4px 0 16px",
                    borderRadius: 8,
                    boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyD}`,
                    padding: 16,
                }}
            >
                <Flex.Item $shrink="shrink">
                    <Box
                        $style={{
                            width: 32,
                            height: 32,
                            backgroundImage:
                                'image-set( url("/images/global-emma-avatar-statement-32.png") 1x, url("/images/global-emma-avatar-statement-32@2x.png") 2x, url("/images/global-emma-avatar-statement-32@3x.png") 3x)',
                        }}
                    />
                </Flex.Item>

                <Flex.Item $shrink="auto" $style={{ paddingTop: 4, paddingBottom: 4 }}>
                    <LoctoolHTMLMessage id="components.claimdescriptionform.note" />
                </Flex.Item>
            </Flex.Container>

            <Box as="form" $style={{ paddingBottom: 24 }} $styleLarge={{ paddingBottom: 0 }} onSubmit={handleSubmit(onSubmit)}>
                <Surface $variant="whiteArrowBottom" $styleLarge={{ boxShadow: theme.shadow.white, "&::after": { content: "normal" } }}>
                    <Box $style={{ padding: "8px 16px 0" }}>
                        <DateInput
                            id="ClaimDescriptionDate"
                            label={<LoctoolMessage id="components.claimdescriptionform.when.label" />}
                            value={dateOfIncident}
                            {...register("dateOfIncident", { validate: value => Validator.claimDateValidator(value, dateOfIncidentMin, dateOfIncidentMax) })}
                            min={DateUtils.format(dateOfIncidentMin)}
                            max={DateUtils.format(dateOfIncidentMax)}
                            placeholder={"DD.MM.YYYY."}
                            dateUtils={DateUtils}
                            error={FormHelpers.getError(errors.dateOfIncident)}
                        />

                        {claimCountryData.countryList.length > 1 && (
                            <Select
                                key={claimCountryData.defaultValue?.name}
                                id="50d8"
                                label={<LoctoolMessage id="components.claimdescriptionform.where.label" />}
                                placeholder={Loctool.instance.formatMessage({ id: "components.claimdescriptionform.where.placeholder" })}
                                {...register("claimCountryCode", { validate: Validator.required })}
                                errorText={FormHelpers.getError(errors.claimCountryCode)}
                            >
                                {claimCountryData.countryList.map(item => {
                                    return (
                                        <option key={item.countryCode} value={item.countryCode}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        )}

                        <Box $style={{ "&&&": { textarea: { minHeight: 164 } } }} $styleMedium={{ "&&&": { textarea: { minHeight: 124 } } }}>
                            <TextField
                                id="ClaimDescriptionText"
                                label={<LoctoolMessage id="components.claimdescriptionform.whathappened.label" />}
                                placeholder={Loctool.instance.formatMessage({ id: "components.claimdescriptionform.whathappened.hint" })}
                                maxLength={Constants.claimDescriptionMaxLength}
                                maxRows={10}
                                multiline
                                {...register("description", { validate: Validator.required })}
                                errorText={FormHelpers.getError(errors.description)}
                                helperText={
                                    <Box $style={{ color: theme.color.blueL, textAlign: "right" }}>
                                        {description.length} / {Constants.claimDescriptionMaxLength}
                                    </Box>
                                }
                            />
                        </Box>

                        <CurrencyInput
                            id="ClaimDescriptionExtent"
                            label={<LoctoolMessage id="components.claimdescriptionform.amount.label" />}
                            placeholder={Loctool.instance.formatMessage({ id: "components.claimdescriptionform.amount.hint" })}
                            {...register("claimAmount", { validate: Validator.required })}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setValue("claimAmount", event.target.value, { shouldValidate: true });
                            }}
                            defaultValue={claimInputData?.claimAmount?.toString() ?? ""}
                            errorText={FormHelpers.getError(errors.claimAmount)}
                        />

                        <BankAccountNumberInput
                            id="ClaimDescriptionIBAN"
                            label={<LoctoolMessage id="components.claimdescriptionform.iban.label" />}
                            placeholder={Loctool.instance.formatMessage({ id: "components.claimdescriptionform.iban.hint" })}
                            {...register("iban", { validate: Validator.ibanValidator })}
                            errorText={FormHelpers.getError(errors.iban)}
                            customSuffixElement={
                                <Box $style={{ pointerEvents: "all", position: "absolute", top: 8, right: 8, zIndex: 1 }}>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button.Secondary tabIndex={-1} btnLabel={"info"} icon={<SVGChrIcon32BasicsQuestionMark />} size="xsmall" />
                                        </TooltipTrigger>
                                        <TooltipContent align="end" alignOffset={-16}>
                                            <Box $style={{ width: "calc(100vw - 3.5 * 16px)" }} $styleSmall={{ width: 320, minHeight: 72 }} $styleMedium={{ width: 374 }}>
                                                <LoctoolHTMLMessage id="components.claimdescriptionform.iban.tooltip" />
                                            </Box>
                                        </TooltipContent>
                                    </Tooltip>
                                </Box>
                            }
                        />
                    </Box>

                    <Box $style={{ maxWidth: 242 + 2 * 16, margin: "0 auto", padding: "24px 16px" }}>
                        <Button.Primary type="submit" btnLabel={<LoctoolMessage id="components.claimdescriptionform.btn.continue" />} isExpanded />
                    </Box>

                    <Surface className="hide-for-large" $variant="whiteArrowBottomShadow" />
                </Surface>
            </Box>
        </>
    );
};
